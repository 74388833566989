import { nanoid } from 'nanoid'
import React from 'react'

import SlideManual from './SlideManual'
import { tutorials } from '../../../../urls'

interface Manual {
    title: string
    imgPath: string
    contentArray: any
}
export default function AgentSlideManuals(): JSX.Element {
    const manuals: Manual[] = [
        {
            title: 'Start using BSO Club',
            imgPath: tutorials.thumbnails['slide-1'],
            contentArray: [
                { img: tutorials['start-using-bso-club']['slide-1'] },
                { img: tutorials['start-using-bso-club']['slide-2'] },
                { img: tutorials['start-using-bso-club']['slide-3'] },
                //        {  img: tutorials['start-using-bso-club']['slide-4'] },
                { img: tutorials['start-using-bso-club']['slide-5'] }
            ]
        },
        {
            title: 'Benefits of Verified & Licensed Profile',
            imgPath: tutorials.thumbnails['slide-2'],
            contentArray: [
                { img: tutorials['benefits-of-verified']['slide-1'] },
                //   {  img: tutorials['benefits-of-verified']['slide-2']},
                { img: tutorials['benefits-of-verified']['slide-3'] }]
        },
        {
            title: 'Advertise BSO Units',
            imgPath: tutorials.thumbnails['slide-3'],
            contentArray: [
                { img: tutorials['advertise-bso-units']['slide-1'] },
                { img: tutorials['advertise-bso-units']['slide-2'] },
                { img: tutorials['advertise-bso-units']['slide-3'] },
                { img: tutorials['advertise-bso-units']['slide-4'] }
                //   {  img: tutorials['advertise-bso-units']['slide-5']  }
            ]
        },
        {
            title: 'Book Your First Viewing',
            imgPath: tutorials.thumbnails['slide-4'],
            contentArray: [
                { img: tutorials['book-your-first-viewing']['slide-1'] },
                { img: tutorials['book-your-first-viewing']['slide-2'] },
                { img: tutorials['book-your-first-viewing']['slide-3'] },
                { img: tutorials['book-your-first-viewing']['slide-4'] },
                { img: tutorials['book-your-first-viewing']['slide-5'] }
                // {  img: tutorials['book-your-first-viewing']['slide-6'] }
            ]
        },
        {
            title: 'Business Scheduler & Tasks',
            imgPath: tutorials.thumbnails['slide-5'],
            contentArray: [
                { img: tutorials['business-scheduler-tasks']['slide-1'] },
                { img: tutorials['business-scheduler-tasks']['slide-2'] },
                { img: tutorials['business-scheduler-tasks']['slide-3'] }

            ]
        },
        {
            title: 'Submit Your First Offer',
            imgPath: tutorials.thumbnails['slide-6'],
            contentArray: [
                { img: tutorials['submit-your-first-offer']['slide-1'] },
                { img: tutorials['submit-your-first-offer']['slide-2'] },
                { img: tutorials['submit-your-first-offer']['slide-3'] },
                { img: tutorials['submit-your-first-offer']['slide-4'] },
                { img: tutorials['submit-your-first-offer']['slide-5'] }
                //  { img: tutorials['submit-your-first-offer']['slide-6'] }
            ]
        },
        // {
        //     title: 'Manage Your Direct Units and Clients',
        //     imgPath: '/assets/media/tutorials/thumbnail/7.png',
        //     contentArray: [
        //         {  img: defaultImage },
        //         { img: defaultImage }
        //     ]
        // },
        {
            title: 'Get connected through BSO Chat',
            imgPath: tutorials.thumbnails['slide-8'],
            contentArray: [
                { img: tutorials['bso-chat-magic']['slide-1'] },
                { img: tutorials['bso-chat-magic']['slide-2'] },
                { img: tutorials['bso-chat-magic']['slide-3'] },
                { img: tutorials['bso-chat-magic']['slide-4'] },
                { img: tutorials['bso-chat-magic']['slide-5'] },
                { img: tutorials['bso-chat-magic']['slide-6'] },
                { img: tutorials['bso-chat-magic']['slide-7'] }
                // {  img: tutorials['bso-chat-magic']['slide-8'] }
            ]
        },
        {
            title: 'BSO Club Add-ons',
            imgPath: tutorials.thumbnails['slide-9'],
            contentArray: [
                { img: tutorials['bso-club-add-ons']['slide-1'] },
                { img: tutorials['bso-club-add-ons']['slide-2'] },
                { img: tutorials['bso-club-add-ons']['slide-3'] },
                { img: tutorials['bso-club-add-ons']['slide-4'] }
                //  {  img: tutorials['bso-club-add-ons']['slide-5'] }
            ]
        }
        // {
        //     title: 'Earn Extra Money with BSO Club',
        //     imgPath: '/assets/media/tutorials/thumbnail/10.png',
        //     contentArray: [
        //         { img: defaultImage },
        //         { img: defaultImage }
        //     ]
        // },
        // {
        //     title: 'BSO Broker Rating',
        //     imgPath: '/assets/media/tutorials/thumbnail/11.png',
        //     contentArray: [
        //         { img: '/assets/media/tutorials/BSO_BROKER_RATING/1.png' },
        //         { img: '/assets/media/tutorials/BSO_BROKER_RATING/2.png' },
        //         { img: '/assets/media/tutorials/BSO_BROKER_RATING/3.png' },
        //         { img: '/assets/media/tutorials/BSO_BROKER_RATING/4.png' }
        //     ]
        // }
        // {
        //     title: 'BSO Credits',
        //     imgPath: '/assets/media/tutorials/thumbnail/12.png',
        //     contentArray: [
        //         {  img: defaultImage },
        //         { img: defaultImage }
        //     ]
        // }
    ]

    return (
        <div className="scroll slides-manual d-flex flex-row gap-4 w-100 scrollbar-width overflow-auto px-4 mb-4">
            {manuals.map((el: Manual, index: number) => {
                return (
                    <SlideManual key={nanoid() + index} title={el.title} contentArray={el.contentArray} imgPath={el.imgPath} />
                )
            })}
        </div>
    )
}
