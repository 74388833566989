
import { getErrorss } from '../../../functions/errors/GetErrors'
import { ACCESS_KEY, URL_MAIN_API_APP, USER_ID_KEY } from '../../../urls'
import { dataFetchingFinish, dataFetchingStart } from '../../slices/modalSlice'

import { type AppDispatch, type RootState } from '../../store'
import { createFreeSubscriptionAgent } from './createFreeSubscriptionAgent'

export interface IOptions {
    isTestMode: boolean
    selectedPeriod: string
    orderID: number
    areas?:string[]
}

export const cancelSubscriptionAgent = ( options?: {
    orderID: number
    isTestMode: boolean
}) => async (dispatch: AppDispatch, getState: () => RootState) => {

    const jwt = window.localStorage.getItem(ACCESS_KEY)
    const testing = options?.isTestMode
    const orderID = options?.orderID
    const userID = window.localStorage.getItem(USER_ID_KEY) ?? ''
  
    try {
        dispatch(dataFetchingStart())

        const sendObject = { id: String(orderID), testing }
        const response = await fetch(`${URL_MAIN_API_APP}/payment/cancelSubscription`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`
            },
            body: JSON.stringify(sendObject)
        })
        const dataJSON = await response.json()
        // console.log(dataJSON,"dataJSON")
        if (dataJSON.success) {
            if (dataJSON.response.error != null) {
                dispatch(dataFetchingFinish())
                return { ...dataJSON, textNavigate: '', isSuccessful: false, textNotification: getErrorss(dataJSON) }
            } else {
                const responseCancelJSON = await dispatch(createFreeSubscriptionAgent(true))
                //  console.log(responseCancelJSON,"responseCancelJSON")
                dispatch(dataFetchingFinish()) 
                return { ...responseCancelJSON}
            }
        } else {
            dispatch(dataFetchingFinish())
            return { textNavigate: '', isSuccessful: false, textNotification: '' }
        }
    } catch (error) {
        console.log(error)
        dispatch(dataFetchingFinish())
    }
}
